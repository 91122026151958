import React, { PureComponent } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import Hero from '../components/hero'
import MainContent from '../components/mainContent'
import Post from '../components/postItems'

export default class TagTemplate extends PureComponent {
  render () {
    const tag = this.props.data.contentfulTag
    const { name, posts, cover } = tag
    return (
      <Layout location={this.props.location}>
        <Helmet>
          <title>{name}</title>
        </Helmet>
        <Hero
          className='tag-cover'
          backgroundImageSrc={cover && cover.fluid.src}
        >
          <h1 className='large-text tag-name'>Tag: {name}</h1>
          <div className='post-count'>{posts ? posts.length : 0} post</div>
        </Hero>
        <MainContent>
          <Post.List>
            {posts &&
              posts.map(post => <Post.ListItem key={post.id} post={post} />)}
          </Post.List>
        </MainContent>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query ContentfulTagBySlug($slug: String!) {
    contentfulTag(slug: { eq: $slug }) {
      name: title
      slug
      cover {
        ...HeroBackgroundImage
      }
      posts: post {
        id
        title
        slug
        createdAt
        body {
          childMarkdownRemark {
            timeToRead
          }
        }
        author {
          slug
          name
        }
        tags {
          id
          name: title
          slug
        }
        featuredImage {
          fluid(quality: 85, maxWidth: 800) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
